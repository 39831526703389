import pic from '../assets/500x500.png';

const CourseData = [
	{
		title: 'Drug/Alcohol Abuse in Workplace',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: "Don't do drugs at work!!!!!",
		img: pic,
	},

	{
		title: 'Respiratory Safety',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'try to breathe oxygen instead of other stuff',
		img: pic,
	},

	{
		title: 'Radiation Safety',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'radiation? not good.',
		img: pic,
	},

	{
		title: 'Emergency Preparedness and Response ',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'have you guys seen that show Preppers?',
		img: pic,
	},

	{
		title: 'Electrical Safety',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'humans conduct electricity immaculately',
		img: pic,
	},

	{
		title: 'Confined Spaces Safety',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'i cant think of something funny for this one',
		img: pic,
	},

	{
		title: 'Lockout Tagout Procedure',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'alohomora!',
		img: pic,
	},

	{
		title: 'Machine Guarding',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'that machines gonna need guarding from me',
		img: pic,
	},

	{
		title: 'Material Handling and Storage',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},

	{
		title: 'Bloodborne Pathogens',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'already sick of this bit',
		img: pic,
	},

	{
		title: 'Chemical Handling and Storage',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},

	{
		title: 'Active Shooter/Active Threat',
		category: 'safety',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},

	{
		title: 'Effective Communication',
		category: 'leadership',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Time Management',
		category: 'leadership',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Emotional Intelligence',
		category: 'leadership',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Conflict Resolution',
		category: 'leadership',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Building High Performance Teams',
		category: 'leadership',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Developing Organizational Culture',
		category: 'leadership',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},

	{
		title: 'Diversity, Equity, and Inclusion ',
		category: 'human-resources',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Performance Management',
		category: 'human-resources',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Recruitment & Retention',
		category: 'human-resources',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Strategic HR Management and Planning',
		category: 'human-resources',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Value Stream or Process Mapping',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'How to Create Standard Work',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Total Productive Maintenance',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Error Proofing/Cost of Poor Quality',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Data Driven Problem Solving',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Root Cause Analysis',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Project Charter Development',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'How to Identify 8 Types of Wastes',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Visual Management',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: '5S OR 6S (workplace organization system)',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: '5 Whys Analysis',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Balancing workload and flow',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'How to Lead and Inspire Change',
		category: 'continuous-improvement',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Cybersecurity',
		category: 'technology',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'OneDrive',
		category: 'technology',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Google Drive',
		category: 'technology',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Microsoft Excel',
		category: 'technology',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Software ',
		category: 'technology',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Programming ',
		category: 'technology',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},

	{
		title: 'Auto CAD ',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: '3D and Animation ',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Industry 4.0',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Fluid Power and Electrohydraulic/Pneumatics',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Electricity and Electronics',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Predictive and Preventative Maintenance',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Drafting Fundamentals',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Robot Maintenance',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Controls and Instrumentation',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Welding and Fabrication',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	{
		title: 'Additive Manufacturing',
		category: 'technical',
		industry: 'business',
		format: 'in-person',
		level: 'beginner',
		language: ['English', 'Spanish'],
		description: 'toxic chemicals? not for eating nor drinking. trust me.',
		img: pic,
	},
	// 	title: 'Business Safety 101',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Safety 102',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Safety 103',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Business Safety 101',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Safety 102',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Safety 103',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Business Safety 101',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Safety 102',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Safety 103',
	// 	category: 'safety',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Safety 101',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Safety 102',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Safety 103',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Safety 101',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Safety 102',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Safety 103',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Safety 101',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Safety 102',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Safety 103',
	// 	category: 'safety',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Safety 101',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Safety 102',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Safety 103',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Safety 101',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Safety 102',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Safety 103',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Safety 101',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Safety 102',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Safety 103',
	// 	category: 'safety',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Business Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Business Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Business Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Compliance 101',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Compliance 102',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Compliance 103',
	// 	category: 'compliance',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Business Training 101',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Training 102',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Training 103',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Business Training 101',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Training 102',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Training 103',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Business Training 101',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Training 102',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Business Training 103',
	// 	category: 'training',
	// 	industry: 'business',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Training 101',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Training 102',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Training 103',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Training 101',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Training 102',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Training 103',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Health Training 101',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Training 102',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Health Training 103',
	// 	category: 'training',
	// 	industry: 'health',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Training 101',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Training 102',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Training 103',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'in-person',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Training 101',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Training 102',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Training 103',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'online',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },

	// {
	// 	title: 'Automotive Training 101',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'beginner',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Training 102',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'intermediate',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
	// {
	// 	title: 'Automotive Training 103',
	// 	category: 'training',
	// 	industry: 'automotive',
	// 	format: 'hybrid',
	// 	level: 'advanced',
	// 	language: ['English', 'Spanish', 'Chinese'],
	// 	description: 'This is a description for BS101',
	// 	img: pic,
	// },
];

export default CourseData;
