import Header from '../components/Header/Header';
import CourseData from '../components/CourseData';
import { Dropdown, Card, Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import './Home/HomeStyles.css';
import { FaSearch, FaWrench } from 'react-icons/fa';
import { BiTrafficCone, BiLineChart } from 'react-icons/bi';
import { GiHumanPyramid } from 'react-icons/gi';
import { GrUserAdmin } from 'react-icons/gr';
import { HiDesktopComputer } from 'react-icons/hi';

const Courses = () => {
	const [filter, setFilter] = useState({
		category: '',
		industry: '',
		format: '',
		level: '',
		language: '',
		search: '',
	});

	//filter handlers
	const handleCategory = (event) => {
		setFilter((previousState) => {
			return { ...previousState, category: event.target.value };
		});
	};
	const handleIndustry = (event) => {
		setFilter((previousState) => {
			return { ...previousState, industry: event.target.value };
		});
	};
	const handleFormat = (event) => {
		setFilter((previousState) => {
			return { ...previousState, format: event.target.value };
		});
	};
	const handleLevel = (event) => {
		setFilter((previousState) => {
			return { ...previousState, level: event.target.value };
		});
	};
	const handleSearch = (event) => {
		setFilter((previousState) => {
			return { ...previousState, search: event.target.value };
		});
	};
	const handleLanguage = (event) => {
		setFilter((previousState) => {
			return { ...previousState, language: event.target.value };
		});
	};

	//modal handlers
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => {
		setShow(true);
	};

	const FilterData = CourseData.filter((course) => course.category.includes(filter.category) && course.industry.includes(filter.industry) && course.format.includes(filter.format) && course.level.includes(filter.level) && (course.title.toLowerCase().includes(filter.search) || course.title.includes(filter.search)));

	return (
		<>
			<Header />
			<main className="home">
				<wrapper class="wrapper" style={{ display: 'flex', position: 'relative', top: '120px' }}>
					<div class="course-content">
						<div className="filter-buttons">
							<div class="filter-button">
								<p>Select Category</p>
								<select id="category" onChange={handleCategory}>
									<option value=""></option>
									<option value="safety">Safety</option>
									<option value="leadership">Leadership</option>
									<option value="human-resources">Human Resources</option>
									<option value="continuous-improvement">Continuous Improvement</option>
									<option value="technology">Technology</option>
									<option value="technical">Technical</option>
								</select>
							</div>

							<div class="filter-button">
								<p>Select Industry</p>
								<select id="industry" onChange={handleIndustry}>
									<option value=""></option>
									<option value="auto">Automotive</option>
									<option value="health">Healthcare</option>
									<option value="business">Business</option>
								</select>
							</div>

							<div class="filter-button">
								<p>Select Format</p>
								<select id="format" onChange={handleFormat}>
									<option value=""></option>
									<option value="online">Online</option>
									<option value="in-person">In-Person</option>
									<option value="hybrid">Hybrid</option>
								</select>
							</div>

							<div class="filter-button">
								<p>Select Level</p>
								<select id="level" onChange={handleLevel}>
									<option value=""></option>
									<option value="beginner">Beginner</option>
									<option value="intermediate">Intermediate</option>
									<option value="advanced">Advanced</option>
								</select>
							</div>

							<div class="filter-button">
								<p>Select Language</p>
								<select id="language" onChange={handleLanguage}>
									<option value=""></option>
									<option value="english">English</option>
									<option value="spanish">Spanish</option>
									<option value="mandarin">Mandarin</option>
								</select>
							</div>
							<div class="search-bar" style={{ display: 'flex' }}>
								<FaSearch size={20} style={{ color: '#000', margin: 'auto 10px' }} />
								<input style={{ width: '100%', height: '100%', margin: 'auto 0', borderRadius: '1rem', padding: '.5rem' }} onChange={handleSearch} />
							</div>
						</div>

						<div class="course-container">
							{FilterData.map((val, ind) => {
								switch (val.category) {
									case 'leadership':
										return (
											<Card onClick={handleShow} style={{ width: '18rem', borderRadius: '1rem' }}>
												<div class="course-thumbnail leadership">
													<GrUserAdmin size={50} />
												</div>
												<Card.Body>
													<Card.Title>{val.title}</Card.Title>
													<Card.Text>{val.description}</Card.Text>
													<div class="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
														<button class={val.format}>{val.format[0].toUpperCase() + val.format.substring(1)}</button>
														<Dropdown>
															<Dropdown.Toggle variant="success" id="dropdown-basic">
																Languages
															</Dropdown.Toggle>

															<Dropdown.Menu>
																{val.language.map((val, ind) => {
																	return <Dropdown.Item href="#/action-1">{val}</Dropdown.Item>;
																})}
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</Card.Body>
											</Card>
										);

									case 'safety':
										return (
											<Card onClick={handleShow} style={{ width: '18rem', borderRadius: '1rem' }}>
												<div class="course-thumbnail safety">
													<BiTrafficCone size={50} />
												</div>
												<Card.Body>
													<Card.Title>{val.title}</Card.Title>
													<Card.Text>{val.description}</Card.Text>
													<div class="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
														<button class={val.format}>{val.format[0].toUpperCase() + val.format.substring(1)}</button>
														<Dropdown>
															<Dropdown.Toggle variant="success" id="dropdown-basic">
																Languages
															</Dropdown.Toggle>

															<Dropdown.Menu>
																{val.language.map((val, ind) => {
																	return <Dropdown.Item href="#/action-1">{val}</Dropdown.Item>;
																})}
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</Card.Body>
											</Card>
										);

									case 'human-resources':
										return (
											<Card onClick={handleShow} style={{ width: '18rem', borderRadius: '1rem' }}>
												<div class="course-thumbnail human-resources">
													<GiHumanPyramid size={50} />
												</div>
												<Card.Body>
													<Card.Title>{val.title}</Card.Title>
													<Card.Text>{val.description}</Card.Text>
													<div class="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
														<button class={val.format}>{val.format[0].toUpperCase() + val.format.substring(1)}</button>
														<Dropdown>
															<Dropdown.Toggle variant="success" id="dropdown-basic">
																Languages
															</Dropdown.Toggle>

															<Dropdown.Menu>
																{val.language.map((val, ind) => {
																	return <Dropdown.Item href="#/action-1">{val}</Dropdown.Item>;
																})}
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</Card.Body>
											</Card>
										);

									case 'continuous-improvement':
										return (
											<Card onClick={handleShow} style={{ width: '18rem', borderRadius: '1rem' }}>
												<div class="course-thumbnail continuous-improvement">
													<BiLineChart size={50} />
												</div>
												<Card.Body>
													<Card.Title>{val.title}</Card.Title>
													<Card.Text>{val.description}</Card.Text>
													<div class="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
														<button class={val.format}>{val.format[0].toUpperCase() + val.format.substring(1)}</button>
														<Dropdown>
															<Dropdown.Toggle variant="success" id="dropdown-basic">
																Languages
															</Dropdown.Toggle>

															<Dropdown.Menu>
																{val.language.map((val, ind) => {
																	return <Dropdown.Item href="#/action-1">{val}</Dropdown.Item>;
																})}
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</Card.Body>
											</Card>
										);

									case 'technology':
										return (
											<Card onClick={handleShow} style={{ width: '18rem', borderRadius: '1rem' }}>
												<div class="course-thumbnail technology">
													<HiDesktopComputer size={50} />
												</div>
												<Card.Body>
													<Card.Title>{val.title}</Card.Title>
													<Card.Text>{val.description}</Card.Text>
													<div class="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
														<button class={val.format}>{val.format[0].toUpperCase() + val.format.substring(1)}</button>
														<Dropdown>
															<Dropdown.Toggle variant="success" id="dropdown-basic">
																Languages
															</Dropdown.Toggle>

															<Dropdown.Menu>
																{val.language.map((val, ind) => {
																	return <Dropdown.Item href="#/action-1">{val}</Dropdown.Item>;
																})}
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</Card.Body>
											</Card>
										);

									case 'technical':
										return (
											<Card onClick={handleShow} style={{ width: '18rem', borderRadius: '1rem' }}>
												<div class="course-thumbnail technical">
													<FaWrench size={50} />
												</div>
												<Card.Body>
													<Card.Title>{val.title}</Card.Title>
													<Card.Text>{val.description}</Card.Text>
													<div class="card-bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
														<button class={val.format}>{val.format[0].toUpperCase() + val.format.substring(1)}</button>
														<Dropdown>
															<Dropdown.Toggle variant="success" id="dropdown-basic">
																Languages
															</Dropdown.Toggle>

															<Dropdown.Menu>
																{val.language.map((val, ind) => {
																	return <Dropdown.Item href="#/action-1">{val}</Dropdown.Item>;
																})}
															</Dropdown.Menu>
														</Dropdown>
													</div>
												</Card.Body>
											</Card>
										);
									default:
										return <h1>Nice!</h1>;
								}
							})}
						</div>
					</div>
				</wrapper>
			</main>
		</>
	);
};

export default Courses;
