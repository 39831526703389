import { Route, Routes } from 'react-router-dom';

import Home from './routes/Home/Home';
import Contact from './routes/Contact';
import Courses from './routes/Courses';
import Resources from './routes/Resources';
import LearningSolutions from './routes/LearningSolutions';
import Instructors from './routes/Instructors';

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/courses" element={<Courses />} />
				<Route path="/resources" element={<Resources />} />
				<Route path="/learning-solutions" element={<LearningSolutions />} />
				<Route path="/instructors" element={<Instructors />} />
			</Routes>
		</>
	);
}

export default App;
