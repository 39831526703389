import Header from '../components/Header/Header';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';

const Contact = () => {
	// window.open('mailto:test@example.com');
	return (
		<>
			<Header />
			<main className="home">
				<wrapper class="wrapper" style={{ display: 'flex' }}>
					<Form style={{ width: '80vh', margin: '0 auto', background: 'white', padding: '20px', borderRadius: '1em' }}>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Email address</Form.Label>
							<Form.Control type="email" placeholder="Enter email" />
							<Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
						</Form.Group>

						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Name</Form.Label>
							<Form.Control type="name" placeholder="Enter name" />
						</Form.Group>
						<Button variant="primary" type="submit">
							Submit
						</Button>
					</Form>
				</wrapper>
			</main>
		</>
	);
};

export default Contact;
