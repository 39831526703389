import Header from '../components/Header/Header';

const Resources = () => {
	return (
		<>
			<Header />
			<div>Resources</div>
		</>
	);
};

export default Resources;
