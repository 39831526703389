import { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './HeaderStyles.css';
import logo from '../../assets/VA LOGO WHITE.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaBars, FaTimes } from 'react-icons/fa';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { GiFactory } from 'react-icons/gi';

const Header = () => {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);

	const [color, setColor] = useState(false);
	const changeColor = () => {
		if (window.scrollY >= 150) {
			setColor(true);
		} else {
			setColor(false);
		}
	};

	window.addEventListener('scroll', changeColor);
	return (
		<header className={color ? 'header header-bg' : 'header'}>
			<div className="header-left">
				<a className="header-logo mb-md-0 me-md-auto" href="/">
					<img class="logo" src={logo} alt="logo" />
				</a>
			</div>

			<div className="header-right" style={{}}>
				<nav>
					<ul className={click ? 'nav-bar active' : 'nav-bar'}>
						<a href="/">
							<li>HOME</li>
						</a>

						<a href="./courses">
							<li>COURSES</li>
						</a>
						<a href="./learning-solutions">
							<li>LEARNING SOLUTIONS</li>
						</a>

						<a href="./instructors">
							<li>INSTRUCTORS</li>
						</a>
						<a href="./resources">
							<li>RESOURCES</li>
						</a>
						<a href="./contact">
							<li>CONTACT</li>
						</a>
					</ul>
				</nav>
			</div>
		</header>
	);
};

{
	/* <div className="hamburger" onClick={handleClick}>
	{click ? <FaTimes size={20} style={{ color: '#000' }} /> : <FaBars size={20} style={{ color: '#000' }} />}
</div> */
}

export default Header;
