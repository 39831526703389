import Header from '../components/Header/Header';

const Instructors = () => {
	return (
		<>
			<Header />
			<div>Instructors</div>
		</>
	);
};

export default Instructors;
