// import components
import Header from '../../components/Header/Header';
import pic from '../../assets/hero.jpg';
import Holder500 from '../../assets/500x500.png';
import poweredBy from '../../assets/powered_by_white_bppc.png';
// import Footer from '../../components/Footer/Footer';
// import HomeButton from '../../components/Tools/HomeButton/HomeButton';

import './HomeStyles.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

// default landing page
const Home = () => {
	return (
		<>
			<Header />
			<main className="home">
				<wrapper class="home-wrapper">
					<div className="hero">
						<div className="mask">
							<img className="intro-img" src={pic} alt="IntroImg" />
						</div>
						<div className="hero-content">
							<div class="hero-left">
								<h1 style={{ borderBottom: 'solid white 2px', marginBottom: '20px' }}>About Virtual Academy</h1>
								<p>Virtual Academy is the learning solution for small and large sized business that provides a quick registration process and on-the-go training option.</p>
								<p>Virtual Academy is changing the way companies train their employees, customers, and partners by improving employee engagement and retention while increasing employee skill levels.</p>
								<a target="blank" href="https://newsroom.interactivetrainingsystems.com/its-partners-with-brittany-parks-process-consulting/">
									<img class="logo" src={poweredBy} alt="powered-by" style={{ width: '50%', marginTop: '10%' }} />
								</a>
							</div>
							<div class="hero-right">
								<img src={Holder500} alt="holder" style={{ width: '50%' }} />
								<div class="hero-btns">
									<button>Request a Quote</button>
									<button>Book a Demo</button>
								</div>
							</div>
						</div>
					</div>
					{/* <section class="top-row">
						<h1>About Virtual Academy</h1>
						<p>Virtual Academy is the learning solution for small and large sized business that provides a quick registration process and on-the-go training option.</p>

						<p>Virtual Academy is a learning solution that is changing the way companies train their employees, customers, and partners by improving employee engagement and retention while increasing employee skill levels.</p>
					</section> */}

					<section class="content-row">
						<div class="leftside">
							<h2>CUSTOM SOLUTIONS</h2>
							<p>Chat with a learning management solution expert to guide you through customized options that are a great addition to an organizations training program based on your budget and needs</p>
							<button>Learn More</button>
						</div>
						<div class="rightside">
							<img src={Holder500} alt="Holder500" />
						</div>
					</section>
					<section class="content-row">
						<div class="leftside">
							<img src={Holder500} alt="Holder500" />
						</div>
						<div class="rightside">
							<h2>SELF-DIRECTED LEARNING</h2>
							<p>Self paced learning allows information to be absorbed through flexible learning formats at the learners pace based on time and schedule to achieve ultimate knowledge retention</p>
							<button>Learn More</button>
						</div>
					</section>
					<section class="content-row">
						<div class="leftside">
							<h2>TRUSTED INSTRUCTORS</h2>
							<p>Our qualified industry experts bring the latest trends and new information to virtual courses</p>
							<button>Learn More</button>
						</div>
						<div class="rightside">
							<img src={Holder500} alt="Holder500" />
						</div>
					</section>
					<section class="content-row">
						<div class="leftside">
							<img src={Holder500} alt="Holder500" />
						</div>
						<div class="rightside">
							<h2>USER FRIENDLY</h2>
							<p>Selection made simple through our customized learning selection filters that allows to get started immediately</p>
							<button>Learn More</button>
						</div>
					</section>
					<section class="content-row">
						<div class="leftside">
							<h2>UNLIMITED ACCESS</h2>
							<p>Access our complete library of top quality online courses 24/7</p>
							<button>Learn More</button>
						</div>
						<div class="rightside">
							<img src={Holder500} alt="Holder500" />
						</div>
					</section>
					<section class="content-row">
						<div class="leftside">
							<img src={Holder500} alt="Holder500" />
						</div>
						<div class="rightside">
							<h2>MULTIPLE LANGUAGES</h2>
							<p>Learning Solutions for multilingual learners</p>
							<button>Learn More</button>
						</div>
					</section>
				</wrapper>
			</main>
			{/* <Footer /> */}
		</>
	);
};

export default Home;
