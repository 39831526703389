import Header from '../components/Header/Header';
import './Home/HomeStyles.css';

const LearningSolutions = () => {
	return (
		<>
			<Header />
			<main className="home">
				<wrapper class="wrapper" style={{ top: '120px', position: 'relative' }}>
					<table style={{ textAlign: 'center', margin: '0 auto', background: 'white' }}>
						<thead>
							<tr>
								<th>OFFERING</th>
								<th>
									CUSTOM
									<br />
									(EXTENDED)
								</th>
								<th>
									CUSTOM
									<br />
									(LIMITED)
								</th>
								<th>
									COURSE
									<br />
									PURCHASE
									<br />
									(EXTENDED)
								</th>
								<th>
									COURSE
									<br />
									PURCHASE
									<br />
									(LIMITED)
								</th>
								<th>
									COURSE
									<br />
									SUBSCRIPTION
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Ownership of Course</td>
								<td>X</td>
								<td>X</td>
								<td>X</td>
								<td>X</td>
								<td></td>
							</tr>
							<tr>
								<td>Business Branded</td>
								<td>X</td>
								<td>X</td>
								<td>X</td>
								<td>X</td>
								<td></td>
							</tr>
							<tr>
								<td>SCORM Compliant</td>
								<td>X</td>
								<td>X</td>
								<td>X</td>
								<td>X</td>
								<td></td>
							</tr>
							<tr>
								<td>Include Custom Documents</td>
								<td>X</td>
								<td>X</td>
								<td>X</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Create Business Documents</td>
								<td>X</td>
								<td></td>
								<td>X</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Provide Training Images</td>
								<td>X</td>
								<td></td>
								<td>X</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Photo and Video Editing</td>
								<td>X</td>
								<td></td>
								<td>X</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Knowledge Checks</td>
								<td>X</td>
								<td></td>
								<td>X</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Industry Specific Compliance</td>
								<td>X</td>
								<td></td>
								<td>X</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>Certificate of Completion</td>
								<td>X</td>
								<td></td>
								<td>X</td>
								<td></td>
								<td></td>
							</tr>

							<tr>
								<td>Access to complete online course library</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>X</td>
							</tr>
						</tbody>
					</table>

					<h2 class="learning-header">Extended Custom Learning Solution</h2>
					<div class="learning-container" style={{ display: 'flex', justifyContent: 'center' }}>
						<div class="ls-block">
							<h6>Business Branded</h6>
							<p>We brand your existing training with your logo.</p>
						</div>

						<div class="ls-block">
							<h6>Business Documents</h6>
							<p>We include your provided existing business documents in your training.</p>
						</div>

						<div class="ls-block">
							<h6>SCORM Compliant</h6>
							<p>We provide assurance of migration to your specific learning management system.</p>
						</div>
					</div>

					<h2 class="learning-header">Limited Custom Learning Solution</h2>
					<div class="learning-container" style={{ display: 'flex', justifyContent: 'center' }}>
						<div class="ls-block">
							<h6>Business Branded</h6>
							<p>We brand your existing training with your logo.</p>
						</div>
						<div class="ls-block">
							<h6>Business Documents</h6>
							<p>We include your provided existing business documents in your training.</p>
						</div>
						<div class="ls-block">
							<h6>SCORM Compliant</h6>
							<p>We provide assurance of migration to your specific learning management system.</p>
						</div>
						<div class="ls-block">
							<h6>Training Images</h6>
							<p>We help you define the best features of facility specific photos to be included in training.</p>
						</div>
						<div class="ls-block">
							<h6>Photo & Video Editing</h6>
							<p>We provide outstanding photo and video design editing.</p>
						</div>
						<div class="ls-block">
							<h6>Knowledge Checks</h6>
							<p>We employ a team of experts to build custom knowledge checks and completion documentation.</p>
						</div>
						<div class="ls-block">
							<h6>Industry Compliance</h6>
							<p>We help you outline industry compliance features of facility specific regulatory.</p>
						</div>
						<div class="ls-block">
							<h6>Certification</h6>
							<p>We provide a branded certificate of completion.</p>
						</div>
					</div>

					<h2 class="learning-header">Extended Course Purchase Package</h2>
					<div class="learning-container" style={{ display: 'flex', justifyContent: 'center' }}>
						<div class="ls-block">
							<h6>Ownership</h6>
							<p>Purchase existing e-learning course offered as is.</p>
						</div>
						<div class="ls-block">
							<h6>Business Branded</h6>
							<p>We brand your existing training with your logo.</p>
						</div>
						<div class="ls-block">
							<h6>SCORM Compliant</h6>
							<p>We provide assurance of migration to your specific learning management system.</p>
						</div>
						<div class="ls-block">
							<h6>Training Images</h6>
							<p>We help you define the best features of facility specific photos to be included in training.</p>
						</div>
						<div class="ls-block">
							<h6>Photo & Video Editing</h6>
							<p>We provide outstanding photo and video design editing </p>
						</div>
						<div class="ls-block">
							<h6>Knowledge Checks</h6>
							<p>We employ a team of experts to build custom knowledge checks and completion documentation.</p>
						</div>
						<div class="ls-block">
							<h6>Industry Compliance</h6>
							<p>We help you outline industry compliance features of facility specific regulatory.</p>
						</div>
						<div class="ls-block">
							<h6>Certification</h6>
							<p>We provide a branded certificate of completion.</p>
						</div>
					</div>

					<h2 class="learning-header">Limited Course Purchase Package</h2>
					<div class="learning-container" style={{ display: 'flex', justifyContent: 'center' }}>
						<div class="ls-block">
							<h6>Ownership</h6>
							<p>Purchase existing e-learning course offered as is.</p>
						</div>
						<div class="ls-block">
							<h6>SCORM Compliant</h6>
							<p>We provide assurance of migration to your specific learning management system.</p>
						</div>
					</div>

					<h2 class="learning-header">Course Subscription</h2>
					<div class="learning-container" style={{ display: 'flex', justifyContent: 'center' }}>
						<div class="ls-block">
							<h6>E-Learning Access</h6>
							<p>We provide you with an all inclusive access login to all e-learning courses offered.</p>
						</div>
						<div class="ls-block">
							<h6>24/7 Access</h6>
							<p>You will have 24/7 access login permissions to all e-learning courses offered.</p>
						</div>
					</div>
				</wrapper>
			</main>
		</>
	);
};

export default LearningSolutions;
